import { Typography, Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Highlight from "../../../components/Highlight";

const List = styled("ul")(() => ({
  paddingLeft: 19,
  marginBottom: 0,
}));

export const issues = [
  {
    devices: [
      "playstation-4",
      "playstation-5",
      "xbox-one-xs",
      "xbox-series-xs",
      "nintendo-switch-oled",
      "nintendo-switch",
      "nintendo-switch-lite",
    ],
    options: [
      {
        id: "display-issues",
        label: "Display issues",
        header: "having display issues",
      },
      {
        id: "not-powering-on",
        label: "Not powering on",
        header: "not powering on",
      },
      {
        id: "internet-issues",
        label: "Internet issues",
        header: "because it's having internet issues",
      },
      {
        id: "sound-issues",
        label: "Sound issues",
        header: "because it's having sound issues",
      },
      {
        id: "liquid-damage",
        label: "Liquid damage",
        header: "because it has liquid damage",
      },
      { id: "other", label: "Other", header: "" },
    ],
  },
  {
    devices: ["iphone", "ipad", "tablet", "apple-laptop", "windows-laptop"],
    options: [
      {
        id: "broken-screen",
        label: "Broken screen",
        header: "because the screen is broken",
      },
      { id: "not-charging", label: "Not charging", header: "not charging" },
      {
        id: "not-powering-on",
        label: "Not powering on",
        header: "not powering on",
      },
      { id: "not-booting", label: "Not booting", header: "not booting" },
      {
        id: "internet-issues",
        label: "Internet issues",
        header: "because it's having internet issues",
      },
      {
        id: "sound-issues",
        label: "Sound issues",
        header: "because it's having sound issues",
      },
      {
        id: "liquid-damage",
        label: "Liquid damage",
        header: "because it has liquid damage",
      },
      {
        id: "poor-battery-life",
        label: "Poor battery life",
        header: "because it has poor battery life",
      },
      {
        id: "running-slow",
        label: "Running slow",
        header: "because it's running slow",
      },
      {
        id: "low-storage-space",
        label: "Low storage space",
        header: "because it has low storage space",
      },
      { id: "other", label: "Other", header: "" },
    ],
  },
  {
    devices: ["windows-computer", "apple-computer"],
    options: [
      {
        id: "not-powering-on",
        label: "Not powering on",
        header: "not powering on",
      },
      { id: "not-booting", label: "Not booting", header: "not booting" },
      {
        id: "internet-issues",
        label: "Internet issues",
        header: "because it's having internet issues",
      },
      {
        id: "sound-issues",
        label: "Sound issues",
        header: "because it's having sound issues",
      },
      {
        id: "gaming-issues",
        label: "Gaming issues",
        header: "because I'm struggling to play games on it",
      },
      {
        id: "running-slow",
        label: "Running slow",
        header: "becuase it's running slow",
      },
      {
        id: "low-storage-space",
        label: "Low storage space",
        header: "because it has low storage space",
      },
      { id: "other", label: "Other", header: "" },
    ],
  },
  {
    devices: ["other"],
    options: [
      {
        id: "not-powering-on",
        label: "Not powering on",
        header: "not powering on",
      },
      { id: "other", label: "Other", header: "" },
    ],
  },
];

export const consoles = [
  { id: "playstation-4", label: "PlayStation 4" },
  { id: "playstation-5", label: "PlayStation 5" },
  { id: "xbox-one-xs", label: "Xbox One X/S" },
  { id: "xbox-series-xs", label: "Xbox Series X/S" },
  { id: "nintendo-switch-oled", label: "Nintendo Switch OLED" },
  { id: "nintendo-switch", label: "Nintendo Switch" },
  { id: "nintendo-switch-lite", label: "Nintendo Switch Lite" },
];

const ConsoleDescription = (
  <>
    <Typography variant="h6" lineHeight="1.4" marginBottom={2}>
      <Highlight>Same day</Highlight> turnaround if you bring your device into
      the store and book it in before 11AM. Same day service slots are limited
      and are first come first served.
    </Typography>
    <Typography variant="h6" lineHeight="1.4">
      If your console is overheating, we can provide a quick clean service for
      £24 or a full strip down, clean all components and replace thermal
      compound (if applicable) for £42
    </Typography>
  </>
);

const PriceList = (props) => (
  <Box
    sx={{
      borderLeft: { md: "solid 10px #fa8301" },
      paddingLeft: { md: 3 },
      py: { md: 2 },
    }}
  >
    <Highlight>
      <Typography variant="h6" lineHeight="1.4">
        Common repairs
      </Typography>
    </Highlight>
    <Typography variant="body1" lineHeight="1.4">
      <List>
        {props.prices.map((p) => (
          <li>{p}</li>
        ))}
      </List>
    </Typography>
  </Box>
);

export const descriptions = [
  {
    devices: ["playstation-5"],
    description: (
      <Grid container spacing={6}>
        <Grid item xs={12} sm={7} lg={8}>
          {ConsoleDescription}
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <PriceList
            prices={[
              "HDMI Port Replacement £89",
              "Liquid Damage Repair from £60",
              "Software issues from £24",
            ]}
          />
        </Grid>
      </Grid>
    ),
  },
  {
    devices: ["playstation-4"],
    description: (
      <Grid container spacing={6}>
        <Grid item xs={12} sm={7} lg={8}>
          {ConsoleDescription}
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <PriceList
            prices={[
              "HDMI Port Replacement £48",
              "Liquid Damage Repair from £60",
              "Software issues from £24",
            ]}
          />
        </Grid>
      </Grid>
    ),
  },
  {
    devices: ["xbox-series-xs"],
    description: (
      <Grid container spacing={6}>
        <Grid item xs={12} sm={7} lg={8}>
          {ConsoleDescription}
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <PriceList
            prices={[
              "Series X HDMI Port Replacement £89",
              "Series S HDMI Port Replacement £69",
              "Liquid Damage Repair from £60",
              "Software issues from £24",
            ]}
          />
        </Grid>
      </Grid>
    ),
  },
  {
    devices: ["xbox-one-xs"],
    description: (
      <Grid container spacing={6}>
        <Grid item xs={12} sm={7} lg={8}>
          {ConsoleDescription}
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <PriceList
            prices={[
              "HDMI Port Replacement £48",
              "Hard Drive Replacement from £66",
              "Liquid Damage Repair from £60",
              "Software issues from £24",
            ]}
          />
        </Grid>
      </Grid>
    ),
  },
  {
    devices: ["nintendo-switch-oled"],
    description: (
      <Grid container spacing={6}>
        <Grid item xs={12} sm={7} lg={8}>
          {ConsoleDescription}
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <PriceList
            prices={[
              "Charger Port Replacement £89",
              "Liquid Damage Repair from £60",
              "Software issues from £24",
            ]}
          />
        </Grid>
      </Grid>
    ),
  },
  {
    devices: ["nintendo-switch"],
    description: (
      <Grid container spacing={6}>
        <Grid item xs={12} sm={7} lg={8}>
          {ConsoleDescription}
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <PriceList
            prices={[
              "Charger Port Replacement £69",
              "LCD Replacement from £65",
              "Liquid Damage Repair from £60",
              "Software issues from £24",
            ]}
          />
        </Grid>
      </Grid>
    ),
  },
  {
    devices: ["nintendo-switch-lite"],
    description: (
      <Grid container spacing={6}>
        <Grid item xs={12} sm={7} lg={8}>
          {ConsoleDescription}
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <PriceList
            prices={[
              "Charger Port Replacement £59",
              "LCD Replacement from £65",
              "Liquid Damage Repair from £60",
              "Software issues from £24",
            ]}
          />
        </Grid>
      </Grid>
    ),
  },
  {
    devices: ["apple-laptop", "apple-computer"],
    description: (
      <>
        <Typography variant="h6" lineHeight="1.4" marginBottom={2}>
          Our mac repair service is available UK wide as well as locally. We can
          offer courier collection and return delivery by insured courier
          service. Please contact us for more information.
        </Typography>
        <Typography variant="h6" lineHeight="1.4">
          We have 12 years experience repairing macs from simple software
          issues/upgrades, screen replacements, keyboard replacements to board
          level repairs. Whatever the issue, get in touch and we’ll be happy to
          help.
        </Typography>
      </>
    ),
  },
  {
    devices: ["iphone"],
    description: (
      <>
        <Typography variant="h6" lineHeight="1.4" marginBottom={2}>
          <Highlight>iPhone screen repairs</Highlight> can be turned around in
          as quickly as <Highlight>20 minutes</Highlight>. We only provide
          Premium Grade screens at the best price possible. Please call for
          current pricing. Screen replacements include a free screen protector
          for the iPhone X or later,cleaning of charger port and speaker vents.
        </Typography>
        <Typography variant="h6" lineHeight="1.4" marginBottom={2}>
          <Highlight>iPhone batteries</Highlight> replaced within{" "}
          <Highlight>30 minutes</Highlight> and covered by 12 months warranty –
          please get in touch for current pricing.
        </Typography>
        <Typography variant="h6" lineHeight="1.4" marginBottom={2}>
          <Highlight>Other faults</Highlight> such as not powering on, loss of
          sound, liquid damage can be dealt with onsite quickly and
          economically. We have a dedicated micro soldering station on standby
          to repair any component issues including loss of Face ID.
        </Typography>
        <Typography variant="h6" lineHeight="1.4">
          Whatever the problem feel free to pop into the store at your
          convenience for an immediate inspection. We can often give you an idea
          of cause and potential cost to fix the problem within ten minutes.
        </Typography>
      </>
    ),
  },
  {
    devices: ["ipad"],
    description: (
      <>
        <Typography variant="h6" lineHeight="1.4" marginBottom={2}>
          iPad touchscreen repairs can often be turned around same day if
          brought into the store and booked in before 11AM. Same day slots are
          limited and are first come first served.
        </Typography>
        <Typography variant="h6" lineHeight="1.4" marginBottom={2}>
          We only supply and fit premium grade screens covered by 12 months
          parts and labour warranty.
        </Typography>
        <Typography variant="h6" lineHeight="1.4" marginBottom={2}>
          Please call for current pricing. Please provide the model number of
          your iPad which can be found on the back of the device starting with
          the letter A followed by 4 digits.
        </Typography>
        <Typography variant="h6" lineHeight="1.4">
          We offer a wide variety of iPad repairs from LCD replacements to board
          level component repairs on site. Whatever the problem feel free to pop
          into the store at your convenience for an immediate inspection. We can
          often give you an idea of cause and potential cost to fix the problem
          within ten minutes.
        </Typography>
      </>
    ),
  },
  {
    devices: ["windows-computer"],
    description: (
      <>
        <Typography variant="h6" lineHeight="1.4" marginBottom={2}>
          Whether for business or recreational use we know your PC is important
          to you. So, if you’re looking for help with a software issue, not
          booting, no display, not powering on or an upgrade let us know and
          we’ll be happy to help.
        </Typography>
        <Typography variant="h6" lineHeight="1.4">
          PC’s brought into the store and booked in for inspection before 11AM
          can often be turned around same day. Same day slots are limited and
          are first come first served.
        </Typography>
      </>
    ),
  },
  {
    devices: ["windows-laptop"],
    description: (
      <>
        <Typography variant="h6" lineHeight="1.4" marginBottom={2}>
          The convenience of a laptop can often be interrupted by unexpected
          issues, we understand time is of the essence. So, if you have a
          cracked display, liquid damage, broken charger port, software issues,
          not booting or a laptop not powering on we can help. If brought into
          the store and booked in before 11am we can often get your laptop back
          to you the same day.
        </Typography>
        <Typography variant="h6" lineHeight="1.4">
          <Highlight>Gaming laptops</Highlight> are powerful machine and it’s
          not uncommon for them to have power issues. These are often caused by
          faulty components on the motherboard. We offer component level board
          repairs from just £83.00.
        </Typography>
      </>
    ),
  },
  {
    devices: ["tablet"],
    description: (
      <Grid container spacing={6}>
        <Grid item xs={12} sm={7} lg={8}>
          <Typography variant="h6" lineHeight="1.4">
            We can offer repairs of all brands of tablets the prices below will
            give you an idea of costs but please call or pop into the store and
            discuss your requirements so we can confirm potential repair costs
            for you.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <PriceList
            prices={[
              "Screen Replacements from £60",
              "Charger Port Replacement from £40",
              "Battery Replacements from £40",
            ]}
          />
        </Grid>
      </Grid>
    ),
  },
];
